import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout.js";

const Page = () => {
  return (
    <Layout>
      <Head title="Ed Buziak" />
      <h3>Ed Buziak</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        France, Sud-Touraine
      </h4>

      <p>
        <OutboundLink href="https://ello.co/edbuzz">Ello</OutboundLink>
      </p>
      <p>
        Artist... photographer... writer... plant-based... minimalist... fixie
        cyclist... francophile... easy going... studied Interior Design in the
        '60s, worked in TV and film in the '70/80s, became a publisher in the
        '90s... now a stock photographer and artist.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/15893796/ello-optimized-9ecb7c69.jpg"
        alt="Ed Buziak Art"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/15890141/ello-optimized-fcd197b2.jpg"
        alt="Ed Buziak Art"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15890143/ello-optimized-1b587ad3.jpg"
        alt="Ed Buziak Art"
      />
    </Layout>
  );
};

export default Page;
